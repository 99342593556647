import React from "react"
import {graphql} from "gatsby";
import {Container, Row} from 'reactstrap'

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import BloqueIconoTexto from "../components/bloqueIconoTexto"
import {LangContext} from "../components/kbusWrapper";


const ContactoPage = ({ data }) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'contacto', 'titulo')} />
        <Container className="py-5  mb-5">
          <PageTitle title={context.translate(data, 'contacto', 'titulo')}/>
          <Row xs="1" md="3" className="mt-4">
            {context.translate(data, 'canalesDeContactoBloques', 'grupoContactos').map(item =>
              <BloqueIconoTexto
                key={item.id}
                icon={item.icono}
                title={item.titulo}
                children={item.descripcion}
              />
            )}
          </Row>
        </Container>
      </Layout>
      )}
  </LangContext.Consumer>
)

export default ContactoPage

export const pageQuery = graphql`  
  query {
    contacto: strapiContacto {
      titulo
    }
    contacto_eus: strapiContactoEus {
      titulo
    }
    canalesDeContactoBloques: strapiContactoSeccion {
      grupoContactos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
    }
    canalesDeContactoBloques_eus: strapiContactoSeccionEus {
      grupoContactos {
        id
        titulo
        descripcion
        icono {
          publicURL
        }
      }
    }
  }
`